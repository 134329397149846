import { vinnV1Api } from "services/vinn-api-v1";

const baseUrl = "/leads";

export const leadsSlice = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    getLeadsCount: builder.query({
      query: (params) => ({
        url: `${baseUrl}/count`,
        params,
      }),
      providesTags: () => [{ type: "Leads" }],
    }),
    getLeads: builder.query({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
      providesTags: () => [{ type: "Leads" }],
    }),
    getLead: builder.query({
      query: (id) => ({
        url: `${baseUrl}/${id}`,
        params: { view: "admin_show" },
      }),
      providesTags: () => [{ type: "Leads" }],
    }),
    getOrigins: builder.query({
      query: () => ({
        url: `${baseUrl}/origins`,
      }),
    }),
    updateLead: builder.mutation({
      query: (data) => ({
        url: `${baseUrl}/${data.id}`,
        method: "PATCH",
        params: { view: "admin_show" },
        data,
      }),
      invalidatesTags: () => [{ type: "Leads" }],
    }),
    createLead: builder.mutation({
      query: (data) => ({
        url: `${baseUrl}`,
        method: "POST",
        data,
      }),
      invalidatesTags: () => [{ type: "Leads" }],
    }),
  }),
});

export const {
  useGetLeadsCountQuery,
  useGetLeadsQuery,
  useGetLeadQuery,
  useGetOriginsQuery,
  useUpdateLeadMutation,
  useCreateLeadMutation,
} = leadsSlice;
