import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

import PersonAddIcon from "@mui/icons-material/PersonAdd";

import SingleLeadPhoneHeader from "components/data-display/PhoneCallHeader/SingleLeadPhoneHeader/SingleLeadPhoneHeader.component";
import PowerDialerHeader from "components/data-display/PhoneCallHeader/PowerDialerHeader/PowerDialerHeader.component";
import AddNewLeadModal from "features/admin/pages/Leads2.0/components/AddNewLead/AddNewLeadModal.component";
import Timer from "features/admin/components/Timer/Timer.component";

import { useDialer } from "hooks/useDialer.hook";
import { adminNewLeadRoute } from "routes";
import { useStyles } from "./PhoneCallHeader.styles";

const PhoneCallHeader = () => {
  const classes = useStyles();
  const router = useRouter();
  const [leadPage, setLeadPage] = useState();
  const [addLead, setAddLead] = useState(false);

  const { callStatus, callDetails } = useDialer();

  useEffect(() => {
    if (callDetails?.leadId) {
      setLeadPage(adminNewLeadRoute(callDetails?.leadId));
    } else {
      setLeadPage();
    }
  }, [callDetails]);

  return (
    <div
      className={classes.container}
      hidden={!["call", "post"].includes(callStatus)}
    >
      <AddNewLeadModal
        open={addLead}
        onClickAway={() => setAddLead(false)}
        contact={callDetails}
      />
      <Box className={classes.box}>
        <Typography variant="h2" className={classes.contact}>
          {!leadPage || router.asPath === leadPage || !callDetails?.leadId ? (
            callDetails?.name || ""
          ) : (
            <Link href={leadPage}>{callDetails?.name || ""}</Link>
          )}
        </Typography>
        {!callDetails?.id && (
          <Button
            color="primary"
            startIcon={<PersonAddIcon />}
            onClick={() => setAddLead(true)}
            className={classes.addContactButton}
          >
            Add Contact
          </Button>
        )}
        <div className="flexGrow" />
        <Typography
          variant="body2"
          className={classes.time}
          hidden={callStatus !== "call"}
        >
          <Timer />
        </Typography>
        {callStatus === "post" && (
          <Typography className={classes.callMessage}>Call ended</Typography>
        )}
        {callStatus === "call" &&
          (callDetails?.callType === "powerDialer" ? (
            <PowerDialerHeader />
          ) : (
            <SingleLeadPhoneHeader />
          ))}
      </Box>
      {callStatus === "call" && <LinearProgress />}
      {callStatus === "post" && <LinearProgress color="tertiary" />}
    </div>
  );
};

export default PhoneCallHeader;
