import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  box: {
    display: "flex",
    border: "1px solid white",
    gap: "10px",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: `0 0 5px 1px ${theme.palette.primary.main} inset`,
    },
  },
  title: {
    margin: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  contact: {
    padding: 14,
  },
  addContactButton: {
    paddingLeft: "10px",
  },
  time: {
    paddingTop: 18,
    paddingRight: 4,
  },
  callMessage: {
    padding: "14px",
  },
}));
