import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  newLeadIcon: {
    width: 38,
  },
  iconRoot: {
    border: `${theme.shape.border} !important`,
    borderRadius: "8px !important",
    boxShadow: `${theme.shape.boxShadow} !important`,
  },
}));

export default useStyles;
