import { makeStyles } from "@mui/styles";
import theme from "theme";

export const useStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    minWidth: "250px",
    margin: "20px",
    display: "flex",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    zIndex: theme.zIndex.locationPopper,
  },
  popup: {
    padding: 6,
    width: "100%",
    border: `4px solid ${theme.palette.secondary.main}`,
    borderRadius: "8px",
  },
  phoneButtons: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));
