import { vinnV1Api } from "services/vinn-api-v1";

const baseUrl = "/integrations/power_dialer";

const powerDialerApi = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    nextCall: builder.mutation({
      query: (params) => ({
        url: `${baseUrl}/call`,
        method: "POST",
        data: params,
      }),
    }),
  }),
});

export const { useNextCallMutation } = powerDialerApi;
