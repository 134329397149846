import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import StoreIcon from "@mui/icons-material/Store";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import MapIcon from "@mui/icons-material/Map";
import LockIcon from "@mui/icons-material/Lock";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonIcon from "@mui/icons-material/Person";
import InboxIcon from "@mui/icons-material/Inbox";
import SettingsIcon from "@mui/icons-material/Settings";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import DataObjectIcon from "@mui/icons-material/DataObject";
import HomeIcon from "@mui/icons-material/Home";
import { adminCloseLeadsRoute } from "routes";

import dayjs from "dayjs";

const makeAdminLinks = (notifications, colour) => {
  const result = [
    {
      name: "Home",
      icon: HomeIcon,
      url: "/admin",
      cy: "home-nav-icon",
    },
    {
      name: "Close Leads",
      icon: EmojiPeopleIcon,
      url: adminCloseLeadsRoute,
      cy: "leads-nav-icon",
      resource: "leads",
    },
    {
      name: "Inbox",
      icon: InboxIcon,
      url: `/admin/inbox?read=false&view=inbox&sortBy=created_at&orderBy=desc&recipientType=User&futureTime=&futureTime=..${dayjs()
        .endOf("day")
        .format()}`,
      cy: "inbox-nav-icon",
      badge: {
        notifications: notifications.inbox,
        colour,
      },
      resource: "communications",
    },
    {
      name: "Dealerships",
      icon: StoreIcon,
      url: "/admin/dealerships?contractStatus=legacy&contractStatus=signed",
      cy: "dealerships-nav-icon",
      resource: "dealerships",
    },
    {
      name: "Dealer Groups",
      icon: BusinessIcon,
      url: "/admin/dealer-groups",
      cy: "dealer-groups-nav-icon",
      resource: "dealer_groups",
      authLevel: "full_access",
    },
    {
      name: "Admins",
      icon: GroupIcon,
      url: "/admin/users/admins",
      cy: "users-nav-icon",
      resource: "users",
      authLevel: "full_access",
    },
    {
      name: "Customers",
      icon: PersonIcon,
      url: "/admin/users/customers",
      cy: "customers-nav-icon",
      resource: "users",
    },
    {
      name: "Dealers",
      icon: AccountBoxIcon,
      url: "/admin/users/dealers",
      cy: "dealers-nav-icon",
      resource: "dealers_dealerships",
    },
    {
      name: "Vehicles",
      icon: DirectionsCarIcon,
      url: "/admin/listings",
      cy: "listings-nav-icon",
      resource: "listings",
    },
    {
      name: "Mappings",
      icon: MapIcon,
      url: "/admin/mappings",
      cy: "mappings-nav-icon",
      resource: "vehicles",
    },
    {
      name: "Archived Dealerships",
      icon: StoreIcon,
      url: "/admin/archived/dealerships",
      cy: "archived-dealerships-nav-icon",
      resource: "dealerships",
    },
    {
      name: "Security",
      icon: LockIcon,
      url: "/admin/security",
      cy: "groups-nav-icon",
      resource: "groups",
    },
    {
      name: "Leads",
      icon: EmojiPeopleIcon,
      url: "/admin/new-leads?status.name=Unqualified",
      cy: "test-leads-nav-icon",
      badge: {
        notifications: notifications.leads,
        colour,
      },
      resource: "contacts",
    },
    {
      name: "Specs",
      icon: DataObjectIcon,
      url: "/admin/specs",
      cy: "specs-nav-icon",
      resource: "models",
    },
    {
      name: "Reported Users",
      icon: ReportOutlinedIcon,
      url: "/admin/reported-users",
      cy: "reported-users-nav-icon",
      resource: "reported_users",
    },
    {
      name: "Settings",
      icon: SettingsIcon,
      url: "/admin/settings",
      cy: "settings-nav-icon",
      resource: "settings",
    },
  ];

  return result;
};
export default makeAdminLinks;
