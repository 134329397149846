import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import PhoneInput from "components/inputs/PhoneInput/PhoneInput.component";
import ValidatedInput from "components/inputs/ValidatedInput/ValidatedInput.component";

import { createContact } from "requests/contacts-api";
import { enqueueSnackbar } from "actions/notifier-actions";
import { useCreateLeadMutation } from "services/v1-slices/leads-slice";
import { adminNewLeadRoute } from "routes";

import useStyles from "./AddNewLeadModal.styles";

const AddNewLeadModal = ({ open, onClickAway, contact }) => {
  const classes = useStyles();
  const [phone, setPhone] = useState(contact.phone);
  const [email, setEmail] = useState(contact.email);
  const [name, setName] = useState(contact.name);
  const [contactExists, setContactExists] = useState(false);
  const [createLead] = useCreateLeadMutation();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    setPhone(contact.phone);
    setName(contact.name);
    setEmail(contact.email);
  }, [open]);

  const handleSubmit = () => {
    if (!phone || !name) {
      dispatch(
        enqueueSnackbar("Please enter a phone number and name.", {
          variant: "error",
        }),
      );
    } else {
      setIsLoading(true);
      createContact({ phone, name, email })
        .then((response) => {
          if (response.status === 201) {
            return createLead({
              contactId: response.data.id,
              origin: "ve",
              quiet: true,
            });
          }
          dispatch(
            enqueueSnackbar("Error creating contact.", { variant: "error" }),
          );
          setIsLoading(false);
          return Promise.reject();
        })
        .then((response) => {
          if (response.data) {
            router.push(adminNewLeadRoute(response.data.id));
          } else {
            dispatch(
              enqueueSnackbar("Error creating contact.", { variant: "error" }),
            );
          }
        })
        .catch(() => {
          dispatch(
            enqueueSnackbar("Error creating lead.", { variant: "error" }),
          );
          setIsLoading(false);
        });
    }
  };

  const handleClickAway = () => {
    setEmail("");
    setName("");
    setPhone("");
    setContactExists(false);
    onClickAway();
  };

  return (
    <Dialog
      data-component="AddNewLeadModal"
      open={open}
      onClose={handleClickAway}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent data-cy="new-lead-modal">
        <Typography classes={{ root: classes.typographyRoot }}>
          Phone *
        </Typography>
        <ValidatedInput
          component={PhoneInput}
          name="leadPhone"
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={phone || ""}
          disabled={contactExists}
          onChange={(val) => setPhone(val)}
          data-cy="leads-phone"
        />
        <Typography classes={{ root: classes.typographyRoot }}>
          Name *
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          disabled={contactExists}
          value={name || ""}
          className={classes.textField}
          onChange={(event) => setName(event.target.value)}
          data-cy="leads-name"
        />
        <Typography classes={{ root: classes.typographyRoot }}>
          Email
        </Typography>
        <ValidatedInput
          component={TextField}
          name="leadEmail"
          variant="outlined"
          fullWidth
          disabled={contactExists}
          value={email}
          placeholder="sample@vinnauto.com"
          className={classes.textField}
          onChange={(event) => setEmail(event.target.value)}
          data-cy="leads-email"
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          variant="contained"
          onClick={handleSubmit}
          data-cy="leads-submit"
        >
          {contactExists ? "Confirm Using Existing Contact" : "Add New Contact"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNewLeadModal.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  open: PropTypes.bool,
  onClickAway: PropTypes.func,
};

AddNewLeadModal.defaultProps = {
  contact: { name: "", phone: "", email: "" },
  open: false,
  onClickAway: () => {},
};

export default AddNewLeadModal;
