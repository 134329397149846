import React from "react";

import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ToolTip from "@mui/material/Tooltip";

import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";

import { useDialer } from "hooks/useDialer.hook";
import { useStyles } from "./IncomingCallPopup.styles";

const IncomingCallPopup = () => {
  const classes = useStyles();

  const { handlePickup, handleHangup, callStatus, callDetails } = useDialer();

  return (
    <Slide
      direction="down"
      in={callStatus === "pending"}
      mountOnEnter
      unmountOnExit
    >
      <Box className={classes.container}>
        <div className={classes.popup}>
          <Typography variant="h4" className={classes.title}>
            Incoming Call...
          </Typography>
          <div>
            <Typography variant="h5" className={classes.subtitle}>
              {callDetails?.name || "New contact"}
              {" - "}
              {callDetails?.phone || "Unknown number"}
            </Typography>
          </div>
          <div className={classes.phoneButtons}>
            <ToolTip title="Accept Call" placement="bottom">
              <IconButton
                className={classes.accept}
                onClick={handlePickup}
                size="large"
                color="success"
              >
                <CallIcon />
              </IconButton>
            </ToolTip>
            <ToolTip title="Reject Call" placement="bottom">
              <IconButton
                className={classes.reject}
                onClick={handleHangup}
                size="large"
                color="error"
              >
                <CallEndIcon />
              </IconButton>
            </ToolTip>
          </div>
        </div>
      </Box>
    </Slide>
  );
};

export default IncomingCallPopup;
