import { vinnV1Api } from "services/vinn-api-v1";

const baseUrl = "/communications";

export const communicationsSlice = vinnV1Api.injectEndpoints({
  endpoints: (builder) => ({
    getCommunications: builder.query({
      query: (params) => ({
        url: `${baseUrl}`,
        params,
      }),
      providesTags: (_result, _error, params) => [
        params.recipientType === "Conversation"
          ? { type: "Conversations", id: params.recipientId }
          : { type: "Communications" },
      ],
      // optimistic update
      async onQueryStarted({ recipientId, recipientType }, { dispatch }) {
        if (recipientType === "Conversation") {
          dispatch(
            vinnV1Api.util.invalidateTags([
              { type: "Conversations", recipientId },
            ]),
          );
        }
      },
    }),
    getCommunicationsCount: builder.query({
      query: (params) => ({
        url: `${baseUrl}/count`,
        params,
      }),
      providesTags: (_result, _error, params) => [
        params?.recipientType === "Conversation"
          ? { type: "Conversations", id: params.recipientId }
          : { type: "Communications" },
      ],
    }),
    updateCommunications: builder.mutation({
      query: (data) => ({
        url: `${baseUrl}/update_all`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: () => [{ type: "Communications" }],
    }),
    updateCommunication: builder.mutation({
      query: (data) => ({
        url: `${baseUrl}/${data.id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: () => [{ type: "Communications" }],
    }),
  }),
});

export const {
  useGetCommunicationsQuery,
  useLazyGetCommunicationsQuery,
  useGetCommunicationsCountQuery,
  useLazyGetCommunicationsCountQuery,
  useUpdateCommunicationsMutation,
  useUpdateCommunicationMutation,
} = communicationsSlice;
