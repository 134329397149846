import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  rightElem: {
    marginRight: 32,
  },
  flexGrow: {
    flexGrow: 1,
  },
}));
