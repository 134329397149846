import React from "react";

import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ToolTip from "@mui/material/Tooltip";

import { useDialer } from "hooks/useDialer.hook";
import usePowerDialer from "hooks/usePowerDialer.hook";
import { useStyles } from "./PowerDialerHeader.styles";

const PowerDialerHeader = () => {
  const classes = useStyles();

  const { handleHangup, mute, handleMute } = useDialer();

  const handlePowerDial = usePowerDialer();

  return (
    <>
      <ToolTip title={mute ? "Unmute" : "Mute"} placement="bottom">
        <IconButton onClick={handleMute} size="large">
          {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </ToolTip>
      <ToolTip title="End Power Dial" placement="bottom">
        <IconButton onClick={handleHangup} size="large">
          <HighlightOffIcon />
        </IconButton>
      </ToolTip>
      <ToolTip title="Next Lead" placement="bottom">
        <IconButton
          className={classes.rightElem}
          onClick={handlePowerDial}
          size="large"
        >
          <SkipNextIcon />
        </IconButton>
      </ToolTip>
    </>
  );
};

export default PowerDialerHeader;
