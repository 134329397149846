import { useDispatch } from "react-redux";

import { enqueueSnackbar } from "actions/notifier-actions";

import { useNextCallMutation } from "services/v1-slices/power-dialer-slice";
import useUser from "hooks/useUser.hook";

import useRouterFilter from "hooks/useRouterFilter.hook";
import { useDialer } from "hooks/useDialer.hook";

const usePowerDialer = () => {
  const dispatch = useDispatch();
  const [nextCall] = useNextCallMutation();
  const filters = useRouterFilter([]);
  const { user } = useUser();
  const { handleDialOut, handleHangup } = useDialer();

  return () => {
    handleHangup();
    nextCall({ senderId: user.id, view: "power_dialer", ...filters }).then(
      (response) => {
        if (response.error) {
          dispatch(
            enqueueSnackbar("Could not complete power dial", {
              variant: "error",
            }),
          );
        } else {
          handleDialOut({
            callType: "powerDialer",
            id: response.data.contact.id,
            type: "Contact",
            phone: response.data.contact.phone,
            name: response.data.contact.name,
            leadId: response.data.id,
            callCount: response.data.callCount,
          });
        }
      },
    );
  };
};

export default usePowerDialer;
