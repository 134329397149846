import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import camelCase from "lodash/camelCase";

import { deepChangeObjectKeysCase } from "helpers/object-helpers";
import useUser from "hooks/useUser.hook";

const useUserSocket = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const [conversationUpdate, setConversationUpdate] = useState({});
  const [communicationUpdate, setCommunicationUpdate] = useState({});

  const createSubscription = async () => {
    const consumer = (await import("consumer")).default;
    consumer.subscriptions.create(
      {
        channel: "UserChannel",
        id: user.id,
      },
      {
        received(action) {
          const payload = deepChangeObjectKeysCase(action.payload, camelCase);
          switch (action.type) {
            case "listing_update":
              dispatch({
                type: "tutorial/reloadListing",
                payload: payload.id,
              });
              break;
            case "listing_append":
              // TODO: Update listing ID in URL if necessary
              if (window.location.pathname.includes(payload.oldId)) {
                window.location.pathname.replace(payload.oldId, payload.newId);
                dispatch({
                  type: "tutorial/reloadListing",
                  payload: payload.newId,
                });
              }
              break;
            case "conversation_update":
              setConversationUpdate(payload);
              break;
            case "communication_update":
              setCommunicationUpdate(payload);
              break;
            default:
              break;
          }
        },
      },
    );
  };

  useEffect(() => {
    if (user) {
      createSubscription();
    }
  }, []);

  return { conversationUpdate, communicationUpdate };
};

export default useUserSocket;
