import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useDialer } from "hooks/useDialer.hook";

const duration = require("dayjs/plugin/duration");
const objectSupport = require("dayjs/plugin/objectSupport");

dayjs.extend(objectSupport);
dayjs.extend(duration);

const Timer = () => {
  const { callStatus, time, setTime } = useDialer();

  const tick = () => {
    setTimeout(() => {
      if (callStatus === "call") {
        setTime(time + 1);
      }
    }, 1000);
  };

  useEffect(() => {
    if (time > 0) tick();
  }, [time]);

  useEffect(() => {
    if (callStatus === "call" && time === 0) {
      tick();
    } else {
      setTime(0);
    }
  }, [callStatus]);

  return dayjs({ seconds: time }).format("HH:mm:ss");
};

export default Timer;
