import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import lowerCase from "lodash/lowerCase";
import dayjs from "dayjs";

import PhoneCallHeader from "components/data-display/PhoneCallHeader/PhoneCallHeader.component";
import IncomingCallPopup from "components/data-display/IncomingCallPopup/IncomingCallPopup.component";
import Portal from "features/portal/Portal.component";
import SideMenu from "features/portal/components/SideMenu/SideMenu.component";

import { useGetCommunicationsCountQuery } from "services/v1-slices/communication-slice";
import { useGetLeadsCountQuery } from "services/v1-slices/leads-slice";
import { enqueueSnackbar } from "actions/notifier-actions";
import makeAdminLinks from "features/admin/components/AdminPortal/admin-portal-links";
import useUser from "hooks/useUser.hook";
import useUserSocket from "hooks/useUserSocket.hook";
import { useDialer } from "hooks/useDialer.hook";
import useLeadsRefreshSocket from "hooks/useLeadsRefreshSocket.hook";

const AdminPortal = ({ children }) => {
  const dispatch = useDispatch();
  const { communicationUpdate } = useUserSocket();
  useDialer({ initialize: true });
  const { user: currentUser } = useUser();
  const { data: communicationsCountData, isSuccess: isCommunicationsSuccess } =
    useGetCommunicationsCountQuery({
      view: "inbox",
      read: false,
      recipientType: "User",
      recipientId: ["", currentUser.id],
      futureTime: ["", `..${dayjs().endOf("day").format()}`],
    });

  useLeadsRefreshSocket();
  const { data: leadsCountData, isSuccess: isLeadsSuccess } =
    useGetLeadsCountQuery({ read: false });

  const getNotifications = () => ({
    inbox: isCommunicationsSuccess ? communicationsCountData.totalCount : 0,
    leads: isLeadsSuccess ? leadsCountData.totalCount : 0,
  });

  const notifications = useMemo(getNotifications, [
    communicationsCountData,
    leadsCountData,
  ]);
  const { user } = useUser({ redirectTo: "/" });

  const links = makeAdminLinks(notifications, "error", user);

  useEffect(() => {
    if (communicationUpdate?.status === "failed") {
      dispatch(
        enqueueSnackbar(
          `Failed to send ${lowerCase(
            communicationUpdate.type.replace("Communications::", ""),
          )}`,
          { variant: "error" },
        ),
      );
    }
  }, [communicationUpdate]);

  if (!user) return <></>;

  return (
    <Portal sideMenu={<SideMenu defaultLinks={links} />} data-cy="admin-portal">
      <video id="remote-video" hidden="hidden">
        <track kind="captions" />
      </video>
      <video id="local-video" hidden="hidden" muted>
        <track kind="captions" />
      </video>
      <IncomingCallPopup />
      <PhoneCallHeader />
      {children}
    </Portal>
  );
};

AdminPortal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminPortal;
