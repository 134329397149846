import React from "react";

import IconButton from "@mui/material/IconButton";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ToolTip from "@mui/material/Tooltip";

import { useDialer } from "hooks/useDialer.hook";

const SingleLeadPhoneHeader = () => {
  const { mute, handleMute, handleHangup } = useDialer();

  return (
    <>
      <ToolTip title="Mute" placement="bottom">
        <IconButton onClick={handleMute} size="large">
          {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </ToolTip>
      <ToolTip title="Hangup" placement="bottom">
        <IconButton onClick={handleHangup} size="large">
          <CallEndIcon />
        </IconButton>
      </ToolTip>
    </>
  );
};

export default SingleLeadPhoneHeader;
