import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: 12,
  },
  input: {
    "&.form-control": {
      // this is needed to achieve higher specificity than the default
      height: 53,
      width: "100%",
    },
  },
  dropdown: {
    "&.country-list": {
      // this is needed to achieve higher specificity than the default
      zIndex: "100000",
      marginTop: "3px",
    },
  },
}));
