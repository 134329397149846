import { useContext } from "react";

import { DialerContext } from "context/dialer";
import useUser from "./useUser.hook";

export const useDialer = (options) => {
  const context = useContext(DialerContext);

  if (!context) {
    throw new Error("useDialer must be used within a DialerProvider");
  }

  const { user } = useUser();
  const dialer = context.Dialer({ user, ...options });

  return {
    ...context,
    ...dialer,
  };
};
