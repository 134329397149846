import { useMemo } from "react";
import { useRouter } from "next/router";

const useRouterFilter = (array) => {
  const router = useRouter();
  const createFilters = () => {
    const returnFilters = {};
    Object.keys(router.query).forEach((key) => {
      if (array.includes(key) && typeof router.query[key] === "string") {
        returnFilters[key] = [router.query[key]];
      } else {
        returnFilters[key] = router.query[key];
      }
    });
    return returnFilters;
  };

  const filters = useMemo(createFilters, [router.query]);

  return filters;
};

export default useRouterFilter;
