import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { vinnV1Api } from "services/vinn-api-v1";

const useLeadsRefreshSocket = () => {
  const dispatch = useDispatch();
  const createSubscription = async () => {
    const consumer = (await import("consumer")).default;
    consumer.subscriptions.create(
      { channel: "LeadsRefreshChannel" },
      {
        received() {
          dispatch(vinnV1Api.util.invalidateTags([{ type: "Leads" }]));
        },
      },
    );
  };

  useEffect(() => {
    createSubscription();
  }, []);
};

export default useLeadsRefreshSocket;
