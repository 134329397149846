import React from "react";
import PhoneInputLib from "react-phone-input-2";

import { useStyles } from "./PhoneInput.styles";

const PhoneInput = ({ value, onChange, ...rest }) => {
  const classes = useStyles();
  return (
    <span data-cy={rest["data-cy"]}>
      <PhoneInputLib
        {...rest}
        inputProps={{ id: rest?.id, name: rest?.name }} // pass id & name props through to input
        className={classes.root}
        inputClass={classes.input}
        dropdownClass={classes.dropdown}
        value={value}
        // the function signature for this is onChange(value, country, e, formattedValue)
        // https://www.npmjs.com/package/react-phone-input-2#events
        // onBlur, onKeypress, etc still use onBlur(e). this makes sense as the value isn't changing
        onChange={(val, country, e) => {
          let ourVal = val;
          if (val === "") {
            // if the value is cleared, keep the country's dialcode in the box
            ourVal = `+${country.dialCode}`;
          }
          onChange(ourVal, country, e);
        }}
        country="ca"
        preferredCountries={["ca"]}
      />
    </span>
  );
};

export default PhoneInput;
